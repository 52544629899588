$(document).ready(function () {

    // Click event for the SAVE icon
    $('#filiacao_pai_box .save_filiacao').on('click', function () {
        update_filiacao({
            token: $(this).data('token'),
            tipo: 'filiacao_pai',
            valor: $('#filiacao_pai-edit input').val()
        });
    });

    $(' #filiacao_mae_box .save_filiacao').on('click', function () {
        update_filiacao({
            token: $(this).data('token'),
            tipo: 'filiacao_mae',
            valor: $('#filiacao_mae-edit input').val()
        });
    });

    function update_filiacao(data) {
        let csrfToken = data.token;
        let tipo = data.tipo;
        let valor = data.valor;

        if ((tipo==="filiacao_pai" || tipo==="filiacao_mae") && csrfToken) {
            $.ajax({
                url: '/cliente',
                method: 'POST',
                data: JSON.stringify({
                    tipo: tipo,
                    valor: valor
                }),
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json'
                },
                success: function () {
                    if (tipo === 'filiacao_pai')
                        parent = $('#filiacao_pai_box');
                    else if (tipo === 'filiacao_mae')
                        parent = $('#filiacao_mae_box');
                    parent.find('.content_edit').hide();
                    parent.find('.content').html(valor);
                    parent.find('.content').show();
                    parent.find('.save_filiacao, .cancel_filiacao').hide();
                    parent.find('.edit_filiacao').show();
                },
                error: function (xhr) {
                    alert('Error: ' + xhr.responseText);
                }
            });
        }
    }

    // Click event for the EDIT icon
    $('#filiacao_pai_box .edit_filiacao, #filiacao_mae_box .edit_filiacao').on('click', function () {
        let parent = $(this).closest('.row');
        let original = parent.find('.content').html();
        parent.find('.content').hide();
        parent.find('.content_edit').show();
        parent.find('.content_edit input').val(original).focus();
        $(this).hide();
        parent.find('.save_filiacao, .cancel_filiacao').show();
    });

    // Click event for the CANCEL icon
    $('#filiacao_pai_box .cancel_filiacao, #filiacao_mae_box .cancel_filiacao').on('click', function () {
        let parent = $(this).closest('.row');
        parent.find('.content_edit').hide();
        parent.find('.content').show();
        parent.find('.save_filiacao, .cancel_filiacao').hide();
        parent.find('.edit_filiacao').show();
    });

});
